import React from "react";

const WhiteBody = (props) => {
  return (
    <div className={"portBody"} style={styles.text}>
      {props.text}
    </div>
  );
};

const styles = {
  text: {
    color: "#F6F7F8",

  },
};

export default WhiteBody;
