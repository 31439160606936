import React from "react";
import { Container, Row, Col, Media } from "reactstrap";
import BodyBackgroundColor from "react-body-backgroundcolor";
import IntroCase from "../components/IntroCase";  
import Title from "../components/Title";
import Body from "../components/Body";
import DataDifferent from "../components/customComponents/DataDifferent";
import PerfectingFlow from "../components/customComponents/PerfectingFlow";
import CustomCard2 from "../components/CustomCard2";
import DataDifferentBackground from "../images/background/lockdown.png";
import BackgroundFlow from "../images/background/brakesFlowBG.png";
import LegoIntro from "../images/legoIntro.jpg";
import Plan from "../images/plan.jpg";
import Bullet from "../images/icons/bullet.png";
import annotated from "../images/kaluzaAPI/annotated.png";
import map from "../images/kaluzaAPI/map.png";
import schema from "../images/kaluzaAPI/schema.png";
import stories from "../images/kaluzaAPI/stories.png";
import taskModel from "../images/kaluzaAPI/taskModel.png";
import ui from "../images/kaluzaAPI/ui.png";
import Nav from "../components/NavMenu";




const BrakesB2C = (props) => {
  return (
    <BodyBackgroundColor backgroundColor="#F6F7F8">
      <Container>
        <Row>
          <Nav />
          <Col>
            <IntroCase
              titleText={"Designing a user-centred API"}
              subText={
                "Researched and designed an user-centred API schema and shared with a prospective client in an accessible format."
              }
              org={"kaluza"}
            />
          </Col>
        </Row>
        <Row>
          <div style={styles.caseColumn2}>
            <Col>
              <div style={styles.slump}>
                <DataDifferent />
              </div>
            </Col>
          </div>
        </Row>

        <Row>
          <Col style={styles.projectTitle}>
            <div>
              <Title text={"Objectives"} />
            </div>
          </Col>
        </Row>
        <Row style={styles.bodyProject}>
          <Col>
            <Body
              text={
                "I worked in a Product Trio with the Product Manager and a Subject Matter Expert, who formerly worked in the country of our prospective client. I also liaised closely with developers throughout."
              }
            />
          </Col>
          {/* <Col>
            <Body
              text={
                "Design was asked to assist with clarifying user needs in the client’s requirements,"
              }
            />
          </Col> */}
        </Row>
        <Row>
          <Col sm={{ size: "auto", offset: 3 }}>
            <div style={styles.centreText}>
              <Body text={"Design was asked to assist with:"} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            <Media>
              <Media left>
                <img style={styles.bulletImage} src={Bullet} />
              </Media>
              <Media body>
                <div style={styles.bulletText}>
                  <Body text={"clarifying user needs in the client’s requirements"} />
                </div>
              </Media>
            </Media>
          </Col>
          <Col sm={{ size: 6, offset: 3 }}>
            <Media>
              <Media left>
                <img style={styles.bulletImage} src={Bullet} />
              </Media>
              <Media body>
                <div style={styles.bulletText}>
                  <Body text={"co-designing a new variant of our API"} />
                </div>
              </Media>
            </Media>
          </Col>
          <Col sm={{ size: 6, offset: 3 }}>
            <Media>
              <Media left>
                <img style={styles.bulletImage} src={Bullet} />
              </Media>
              <Media body>
                <div style={styles.bulletText}>
                  <Body text={"validating this with the client in an accessible way"} />
                </div>
              </Media>
            </Media>
          </Col>
        </Row>
        {/* <Row>
          <Col xs={{ size: 10, offset: 1 }}>
            <img style={styles.monitor} src={Monitor} />
          </Col>
        </Row> */}
        <Row style={styles.bodyBuilding}>
          <Col>
            <Title text={"Understand our existing offering"} />
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <Body
              text={
                "We had a (huge) schema to explore. Each field had to be validated so I fully understood what it could (and couldn’t) do."
              }
            />
            <Body
              text={
                "I created a questionnaire for the team of developers to collaborate on asynchronously, validating our assumptions about each field."
              }
            />
          </Col>

          <Col xs="6" md="6">
            <img style={styles.mobile} src={Plan} />
          </Col>
          
        </Row>
        <Row>
          <div >
            <Col xs="12">
              <div style={{paddingLeft: 20, paddingTop: 20}}>
                <Title text={"Client requirements"}/>
                {/* <PerfectingFlow /> */}
              </div>
            </Col>
            <Col sm={{ size: 8, offset: 2 }}>
              <CustomCard2 text="The client had provided us with screenshots of existing energy software that they wanted to recreate and asked that we provide certain data for this outcome, using an API we already offer to UK customers." />
            </Col>
            <Col sm={{ size: 8, offset: 2 }}>
              <CustomCard2 text="I started by orienting myself on how this particular market’s smart meter configuration worked, mapping everything with our subject matter expert." />
            </Col>
            <Col sm={{ size: 8, offset: 2 }}>
            <div style={{width: "100%", paddingTop: 30}}>
            <img src={taskModel} style={{width: "100%"}} />
            </div>
            </Col>
            <Col sm={{ size: 8, offset: 2 }}>
              <CustomCard2 text="We broke the client’s requirements into user outcomes, fleshing them out with user needs from past research." />
            </Col>
            <Col sm={{ size: 8, offset: 2 }}>
              <CustomCard2 text="We mapped known user behaviour supporting each outcome (log in, open screen, request data, etc). Working closely with developers, underneath each step of every outcome, we listed assumptions of software behaviour and how our API could best support this." />
            </Col>
            <Col sm={{ size: 8, offset: 2 }}>
              <CustomCard2 text="Then we organised our schema by outcome type and flag missing fields that would be required." />
            </Col>
            {/* <Col sm={{ size: 8, offset: 2 }}>
              <img style={styles.overflow} src={Overflow} />
            </Col> */}
          </div>
        </Row>
        <Row style={styles.bodyBuilding}>
          {/* <Col xs="2" sm="1">
            <img alt="Hotjar" style={styles.hotjar} src={Hotjar} />
          </Col> */}
          <Col xs="10" sm="11">
            <Title text={"Communicating this to the client"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Body
              text={
                "We annotated the client’s UI screenshot with outcomes, user actions, what their software probably does and how our API would support this."
              }
            />
            <div style={{width: "600px"}}>
            <img src={annotated} style={{width: "100%"}} />
            </div>

            <Body
              text={
                "Of course, we also added assumptions and questions for discussion."
              }
            />
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <Body
              text={
                "We raised a bug, but I added a safety net anyway. If a user's email was on file, a dropdown would appear, linking to the log-in page where their email would be auto-filled."
              }
            />
          </Col>
        </Row> */}
        {/* <Row style={styles.monitor}>
          <Col sm={{ size: 3, offset: 2 }}>
            <img style={styles.mobile} src={Pixel1b} />
          </Col>
          <Col sm={{ size: 3, offset: 2 }}>
            <img style={styles.mobile} src={Pixel2b} />
          </Col>
        </Row> */}
        <Row>
          <Col xs="10" style={styles.lastCol}>
            <Title text={"The outcome"} />
            <Body
              text={
                "The client was happy with the illustration and our proposed API. They actioned what we shared and this part of the company workstream was ahead of schedule, over other parts of the pitch."
              }
            />
            <Body
              text={
                "We have since begun an initiative to upskills designers, PMs and researchers on how APIs work. And we are creating templates and training for designing them in a user-centred way."
              }
            />
          </Col>
        </Row>
      </Container>
    </BodyBackgroundColor>
  );
};


const styles = {
  back: {

  },
  lastCol :{ 
    marginTop: "15%"
  },
  overflow: {
    width: "100%",
    paddingTop: "5%"
  },
  slump: {

  },
  caseColumn2: {
    backgroundImage: `url(${LegoIntro})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    height: "700px",
    width: "100vw",
    position: "relative",
    left: "50%",
    right: "50%",
    marginLeft: "-50vw",
    marginRight: "-50vw",
    display: "block",
    paddingTop: 100,
    paddingBottom: 100
  },
  waveRow : {
    marginTop: "5%"
  },
  caseColumn3: {
    backgroundImage: `url(${BackgroundFlow})`,
    width: "100vw",
    height: 1200,
    position: "relative",
    left: "50%",
    right: "50%",
    marginLeft: "-50vw",
    marginRight: "-50vw",
    display: "block",
  },
  projectTitle: {
    paddingTop: "10%",
  },
  bodyProject: {
    paddingTop: "3%",
    paddingBottom: "3%",
  },
  bodyBuilding: {
    paddingTop: "15%",

  },
  centreText: {
    paddingTop: "3%",
    paddingBottom: "5%",
  },
  bulletText: {
    paddingBottom: "5%",
    paddingLeft: "0%",
  },
  bulletImage: {
    paddingTop: "10%",
    width: "75%"
  },
  monitor: {
    overflow: "hidden",
    width: "100%",
    height: "auto",
    paddingTop: "5%",
    paddingBottom: "15%",
  },
  mobile: {
    width: "95%",
    height: "auto",
    paddingBottom: "15%",
  },
  hotjar: {
    height: "auto",
    width: 60,
  },
};



export default BrakesB2C;
