import React from "react";
import BackToTop from "react-easy-back-to-top";
import Chevron from "../images/icons/chevron.svg";

export default function BackTop() {
  return (
    <BackToTop
      icon="fas fa-chevron-up"
      position={{ bottom: "0%", right: "0%" }}
      hover={{ backgroundColor: "gray", opacity: "0.95" }}
      margin="32px"
    />
  );
}
