import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { BrowserRouter as Router, Switch, Route, StaticRouter } from "react-router-dom";
import Landing from './pages/Landing.js';
import BrakesB2C from "./pages/BrakesB2C.js";
import SpecacApp from "./pages/SpecacApp.js";
import SpecacVR from "./pages/SpecacVR.js";
import KaluzaAPI from "./pages/KaluzaAPI.js";
import ScrollToTop from "./components/ScrollToTop";
import BackTop from "./components/BackToTop";


import ContactForm from "./components/ContactForm";

function App() {
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop>
          <Container>
            <Row>
              <Col>
                <Switch>
                  <Route exact path="/" component={Landing} />
                  {/* <Route exact path="/kaluza-api" component={KaluzaAPI} /> */}
                  <Route exact path="/brakes-b2c" component={BrakesB2C} />
                  <Route exact path="/specac-app" component={SpecacApp} />
                  <Route exact path="/specac-vr" component={SpecacVR} />
                </Switch>
                <BackTop />
              </Col>
            </Row>

            {/* <Row id="contact">
              <Col>
                <ContactForm />
              </Col>
            </Row> */}
          </Container>
        </ScrollToTop>
      </Router>
    </React.Fragment>
  );
}

export default App;
